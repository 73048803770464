
.container {
    // width: calc(100vw - 295px);
}

.tableRow {
    cursor: pointer;
    width: 5%;
}

.container__table {
    // overflow : auto;
}

