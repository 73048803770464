
.container {
    // width: calc(100vw - 295px);
}

.tableRow {
    cursor: pointer;
}

.container__table {
    // overflow : auto;
}
