.main_page_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: calc(100vh - 40px);
}

.main_content_wrap {
}

.login_wrap {
  width: 480px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  margin-top: 97px;
  padding: 50px;
}

.title_wrap {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.error_wrap {
  display: flex;
  background: #fa5252;
  border-radius: 5px;
  height: 73px;
  padding: 15px 32px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  // display: none;
}

.title_error {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 4px;
}

.description_error {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.8;
}

.input_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  .eyeIcon {
    position: absolute;
    left: 90%;
    top: 74%;

    &:hover {
      cursor: pointer;
    }
  }
}

.title_input {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn_wrap {
  width: 100%;
  height: 40px;
  background: #003e8c;
  border-radius: 5px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 425px) {
  .main_page_wrap {
    padding: 3%;
    width: 100%;
  }

  .login_wrap {
    width: 100%;
    padding: 3%;
  }

  .error_wrap {
    padding: 3%;
  }

  .title_wrap {
    font-size: 2rem;
    margin-bottom: 2%;
  }

  .main_content_wrap {
    width: 100%;
  }
}
