.text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #3a3a3a;
}
.invalid {
  & > div:first-child {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
.simple_select {
  @extend .text;
}

.dropdown * {
  @extend .text;
}

.lg {
  height: 38px !important;
}
.md {
  height: 34px !important;
}
.xs {
  height: 30px !important;
}

.multiplySelect {
  @extend .text;
}
.dark {
  & div:first-child {
    background: #f4f4f4 !important;
  }
}
.light {
  & div:first-child {
    background: #ffffff !important;
  }
}
.withReset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div:first-child {
    border-radius: 4px 0 0 4px;
    flex: 1 1 auto;
  }
  & button {
    width: 30px;
    height: 30px;
  }
}
.completed > div:first-child {
  border-color: #40a9ff !important;
}
.success_validation_icon {
  position: absolute;
  top: 60%;
  right: 30px;
  transform: translate(0, -50%);
}
.error_validation_icon {
  position: absolute;
  top: 60%;
  right: 30px;
  transform: translate(0, -50%);
  svg path {
    fill: #df0e0f;
  }
}
