.rootHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 24px;
  justify-content: space-between;
}

.headerTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #3A3A3A;
}

.headerCloseIcon {
  flex: 0 0 5%;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #EAEAEA;
}

.rootHeader__mobile {
  padding: 10px !important;
}

.headerTitle__mobile {
  font-weight: 500;
  font-size: 12px !important;
  color: #3A3A3A;
}

.containerForIcons {
  display: flex;
  gap : 20px;
}
