.post{
    display: flex;
    flex-direction: row;

    &__leftSide{
        width: 300px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between ;
        
        &__find{
            width: 90px !important;
            border-radius: 4px !important;
            background-color: black !important;
            color: white !important;
            display: flex !important; 
            justify-content: center !important;
            margin: 0 8px !important;
        }
    }
    &__rightSide{

        &__search{
            padding-top: 20px;
        }
        &__list{
            overflow-y:auto;
            width: 300px;
            padding: 20px;
            height: 300px;
        }
    }
}
.delete{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    &__title{
        margin-bottom: 25px;
        font-weight: 700;
        font-size: medium;
    }
    &__find{
        width: 90px !important;
        border-radius: 4px !important;
        background-color: black !important;
        color: white !important;
        display: flex !important; 
        justify-content: center !important;
        margin: 0 8px !important;
    }
}
