.block {
    border-radius: 4px;
    box-shadow: 0px 4px 15px rgba(223, 222, 230, 0.5);
    padding: 8px;
    background-color: #FBFBFF;
    width: 40vw;

    @media (max-width: 1024px) {
        width: 60vw;
    }

    @media (max-width: 800px) {
        width: 70vw;
    }

    @media (max-width: 600px) {
        width: 80vw;
    }

    @media (max-width: 500px) {
        width: 90vw;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .title {
            font-weight: 500;
            font-size: 18px;
            width: 180px;

            @media (max-width: 1024px) {
                margin-right: 50px;
            }

            @media (max-width: 500px) {
                margin-right: 60px;
                width: 50px;
            }
        }
    }

    .buttons {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .find {
            width: 80px;
            border-radius: 4px;
            background-color: black;
            color: white;
            display: flex;
            justify-content: center;
            margin: 0 8px;
        }

        .cancel {
            width: 160px;
            background-color: white;
            color: black;
            border-radius: 4px;
            border: 1px solid #ced4da;
            display: flex;
            justify-content: center;
        }
    }
}