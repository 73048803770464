.downloadBtn {
    margin-top: 16px;
    a {
        background: black;
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        &:hover {
            color: white !important
        }
    }
}