.body {
    padding: 24px;
    // width: 1000px;

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__item {
            display: flex;
            flex-direction: column;
            width: 40%;
            margin-bottom: 16px;

            &__title {
                margin-bottom: 8px;
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                color: #ced4da;
                font-weight: bold;
            }

            &__value {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    &__gbrList{
        // flex-wrap: nowrap;
        &__title {
            margin-bottom: 8px;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;
            color: #ced4da;
            font-weight: bold;
            
        }
        &__value {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
        }
    }

}