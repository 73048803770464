//TODO вынести в отедльный файл
@mixin field_text_style {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
}

.input_wrap {
  position: relative;
}
.root {
  &::placeholder {
    @include field_text_style;
    font-size: 14px;
    color: #878787;
  }
  @include field_text_style;
  border: 1px solid #878787;
  color: #3A3A3A;
  border-radius: 4px !important;
  font-size: 14px;
  height: 38px;
}
.invalid {
  &:focus {
    //box-shadow: none !important;
    box-shadow: 0 0 0 2px rgba(223, 14, 15, 0.2) !important;
  }
  border: 1px solid #DF0E0F !important;
}
.completed {
  border-color: #40a9ff!important;
}
.empty {
  background: #F4F4F4!important;
  border: 1px solid #BDBDBD!important;
}

.lg {
  height: 38px;
}
.md {
  height: 34px;
}
.xs {
  height: 30px;
}

.success_validation_icon {
  position: absolute;
  top: 60%;
  right: 30px;
  transform: translate(0, -50%);
}
.error_validation_icon {
  position: absolute;
  top: 60%;
  right: 30px;
  transform: translate(0, -50%);
  svg path {
    fill: #DF0E0F;
  }
}